// .contnent {
//   background-repeat: no-repeat;
//   background-size: cover;
//   height: 55vh;
//   text-align: center;
//   position: relative;
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   .overlay {
//     position: absolute; 
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: rgba(0, 0, 0, 0.5); 
// }

//   h1 {
//     color: #FFB606;
//     font-size: 79px;
//     line-height: 1.3em;
//     font-weight: 50;
//     text-shadow: -5px 1px 0px #72591D;
//     font-family: Sans-serif;
//     position: absolute; 
//     z-index: 1; 
//   }
// }

// @media (max-width: 800px) {
//   .contnent h1{
//     font-size: 40px !important;
//   }
//   .home-contnent .p_home_content{
//     font-size: 28px !important;
//   }
// }


.content {
  background-repeat: no-repeat;
  background-size: cover;
  height: 55vh;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .overlay {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
}

  h1 {
    color: #FFB606;
    font-size: 79px;
    line-height: 1.3em;
    font-weight: 50;
    text-shadow: -5px 1px 0px #72591D;
    font-family: Sans-serif;
    position: absolute; 
    z-index: 1; 
  }
}

@media (max-width: 800px) {
  .content h1{
    font-size: 40px !important;
  }
  .home-content .p_home_content{
    font-size: 28px !important;
  }
}