.Container{
  margin: 10% auto;
  width: 80%;
}
.Title{
  margin-bottom: 20px;
  color: #FFB606;
  text-align: center;
}
.Titlee {
  margin-bottom: 20px;
  color: #fff;
  font-size: 15px;
  text-align: center;
}
.Second_Section_title{
  color: #FFB606;
  text-align: center;
  margin-bottom: 20px;
  font-size: 28px
}
.flex{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.grid-container-second{
  grid-gap: 2% !important;
}

@media (max-width: 764px) {
  .grid-container-second {
    grid-template-columns: repeat(auto-fit,minmax(100%, 1fr)) !important;
  }
}
