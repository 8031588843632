   .scroll-to-bottom-button {
    cursor: pointer;
    width: 100%;
    height: 40px;
    font-size: 20px;
    color: #fff;
  }
  .scroll-to-bottom-button:hover {
    border-color: #FFB606;
  }
  