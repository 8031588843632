.form-container {
  margin: 0 auto;
  padding: 5% 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #000;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input[type="text"] {
  width: 80%;
  padding: 1.1%;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
  font-size: 18px;
}

.button {
  color: #000;
  padding: 1% 0;
  border: none;
  border-radius: 3px;
  width: 80%;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #dfdfdf;
}

.user-details {
  margin-top: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.user-details p {
  margin-bottom: 5px;
}


.styled-table {
  border-collapse: collapse;
  margin:0 0 25px 0;
  border: #ccc 1px solid;
  font-size: 18px;
  font-family: 'Arial, Helvetica, sans-serif';
  min-width:80%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  color: #ffffff;
  text-align: left;
}
.styled-table td {
  color: #ae9534;
}

.styled-table th,
.styled-table td {
  padding: 1.1%;
  border: #ccc 1px solid;
  width: 50%;
}
.title{
  color: #ae9534;
  border: #ccc 1px solid;
  width: 80%;
  padding: 1.1%;
}

.styled-table tbody tr {
  background-color: transparent;
}