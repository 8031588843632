.div_img {
  width: 210px;
  height: 210px;
  margin: auto;
  border-style: dashed;
  border-width: 2px;
  border-color: #DCDCFF;
  border-radius: 50%;
  margin-bottom: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4%;
}

.circle_img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
}

.grid-container4 {
  width: 100%;
  margin: 10% auto;
}

.team_grid-item {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.team_grid-item h2 {
  margin: 8% 0 4% 0;
  font-size: 17px;
  font-weight: 400;
  color: #fff;
}
.aboutus_pp{
  color: #B8AFAF;
  font-size: 15px;
  font-weight: 200;
  margin: 20px 0;
}
.team_swiper {
  width: 100%;
  gap: 5% !important;
}

.swipper {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;

}

@media (max-width: 768px) {
  .team_grid-item h2 {
    font-size: 18px;
  }
}


@media (max-width: 320px) {
  .swipper {
    width: 100% !important;
  }
}

.overlay_team {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1s ease; 
  pointer-events: none; 
}

.circle_img:hover .overlay_team {
  opacity: 1;
  pointer-events: auto;
}
