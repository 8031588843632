.contact_card {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin-bottom: 15%;
  }
  
  .contact_card .img_contact {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .SwiperSlide{
    margin: 0 0 10% 0;
  }
  .swiper-pagination {
    // تنسيق الترقيم الغير محدد
    .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
      background-color: #fff; 
      width: 10px;
      height: 10px;
      margin: 0 8px;
      border-radius: 50%;
      cursor: pointer;
    }
  
    // تنسيق الترقيم المحدد
    .swiper-pagination-bullet-active {
      background-color: #FFB606; 
      width: 10px;
      height: 10px;
      margin: 0 8px;
      border-radius: 50%;
      cursor: pointer;
    }
  }
  