nav {
    width: 70%;
    color: #fff;
    display: flex;
    justify-content: space-around;
    padding: 4px 0;
    z-index: 10;
}

nav ul li {
    display: inline-block;
    list-style: none;
    margin: 5px 20px;
    font-size: 16px;
    padding-bottom: 10px;
    position: relative;
}

nav ul li::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 100%;
    height: 2px; 
    background-color: transparent;
}

nav ul li:hover::after,
nav ul li:active::after,
nav ul li.active::after {
    background-color: #ffb606;
}

.Nav_Container ul li {
    position: relative;
}

.Nav_Container ul li.active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #ffb606;
    transition: width 0.3s ease;
}

.Nav_Container ul li:hover::after {
    width: 100%;
}

.menu-icon {
    display: none;
}

@media (max-width: 1000px) {
    nav ul li {
        margin: 10px 15px;
        font-size: 20px;
    }
    .LogoApp .logo {
        width: 100% !important;
    }
}

@media (max-width: 945px) {
    nav {
        position: fixed;
        right: 30px;
        top: 40px;
        justify-content: end !important;
        text-align: center;
    }
    nav ul {
        position: fixed;
        top: 0;
        right: 0;
        background-color: #000;
        z-index: -1;
        width: 50%;
        padding-top: 25%;
        transition: 0.5s;
        bottom: 0;
    }
    nav ul li {
        display: block;
        margin: 25px 40px;
    }
    .menu-icon {
        display: block;
        cursor: pointer;
        width: 9%;
        filter: invert(1) sepia(1) saturate(1) hue-rotate(90deg);
    }
    .LogoApp .logo {
        width: 100% !important;
    }
    .hide-mobile-menu {
        right: -50%;
    }
    nav ul li:hover::after,
    nav ul li:active::after,
    nav ul li.active::after {
        background-color: transparent;
    }
    .Nav_Container ul li {
        position: relative;
    }
    .Nav_Container ul li.active::after {
        background-color: transparent;
    }
    .Nav_Container ul li.active,
    .Nav_Container ul li:hover {
        color: #ffb606;
    }
}
