@media (max-width: 1024px) {
  .num{
    font-size: 28px !important;
  }
}

.green{
  color: #7BC669;
}

.blue{
  color: #4489F3;
}
.yellow{
  color: #FFB606;
}
.red{
  color: #ED7459;
}
.num{
  font-size: 34px !important;
}
