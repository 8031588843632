.Contact_section {
    background-repeat: no-repeat;
    background-position: right;
    background-size: calc(110% / 2) calc(200% / 2);
    display: grid;
    position: relative;
    grid-template-columns: repeat(2, 1fr);
    justify-content: flex-end;
    border-radius: 5px 5px 8px 8px;
}

.customer {
    width: 50px;
    height: 50px;
}
.Conversation, h5{
    font-size: 18px;
}
.Conversation p{
    color: rgb(217, 235, 198);
    font-size: 12px;
    margin-top: 4%;
}
.div2 p{
    color: #a5abb7;
    font-size: 11px;
}
.whatsap_div {
    margin-right: 20px;
    position: fixed;
    bottom: 20px; 
    right: 20px; 
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1000; 
}

.whatsap_div img {
    width: 55px;
    height: 55px;
}
.Support_p{
    font-size: 90% !important;
    color: #363c47 !important;
    line-height: 1.188em !important;
    padding: 2% 0 !important;
}
.Two_button {
    display: flex;
    justify-content: space-between;
    padding: 4%;
    align-items: center;
    gap: 10px;
    margin-top: 7%;
    position: relative;
    background-color: #f5f7f9;
    border-left: 2px solid #2db742 ;
    border-radius: 2px 4px;
    transition: background-color 0.3s, box-shadow 0.3s;
}
.Two_button:hover{
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);  
}

.div2 img {
    border-radius: 50%;
    margin-right: 3%;
}

.First_button {
    display: flex;
    width: 70%;
}

.div2>div a {
    text-decoration: none;
    color: #000;
    cursor: pointer;

}

.whatsap_div a {
    background-color: rgba(255, 255, 255, 0.15);
    color: #fff;
    padding: 9px;
    position: absolute;
    right: 60px;
    z-index: 1111111;
    text-decoration: none;
    border-radius: 11px;
}

.Contact_section .images {
    width: 50%;
}
@media (width: (780px, 1134px)) {
    
    .customer_service {
        width: 40% !important;
    }
}
@media (max-width: 780px) {
    .images {
        display: none;

    }

    .Contact_section {
        grid-template-columns: repeat(1, 1fr);
    }
    .customer_service {
        width: 80% !important;
    }
}

.images ul {
    position: relative;
    top: 290px;
    right: 160px;
    transform: translate(-50%, -50%);
    display: flex;
    width: 350x;
    height: 50px;
}

.images ul li {
    list-style: none;
    width: 50px;
    height: 50x;
    border-radius: 50%;
    background-color: rgb(255, 175, 3);
    animation: dots 1.6s ease-in-out infinite;
}


.images ul li:nth-child(1) {
    top: 60px;
    position: inherit;
}

.images ul li:nth-child(3) {
    top: 50%;
    position: inherit;
}

@keyframes dots {

    0%,
    40%,
    100% {
        transform: scale(0.3);
        transition: 0.3s;
    }

    20% {
        transform: scale(0.6);
        transition: 0.3s;

    }
}

.customer_service {
    right: 1.5%;
    width: 23.8%;
    border-radius: 10px;
    position: fixed;
    bottom: 85px; 
    z-index: 1000; 
}

.hello_customer {
    display: flex;
}

.hello_customer .customer {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #fff;
    padding: 60px 20px;
    width: 100%;
    background-color: rgb(45, 183, 66);
    border-radius: 5px 5px 0 0;
}

.close {
    background-color: rgb(45, 183, 66);
    width: 55px;
    padding: 10px;
    color: #fff;
    font-size: 20px;
    height: 55px;
    border-radius: 50%;
}

.hello_customer .whatsap_icon {
    display: flex;
    justify-content: flex-end;
}
.div2{
    background-color: #ffffff;
    width: 100%;
    padding: 7%;
    border-radius: 0 0 8px 8px;
}