.grid-item2{
    background-color: #000000 !important;
      border-radius: 15px 15px 15px 15px !important;
      box-shadow: 6px 9px 50px -28px #FFB606 !important;
      border:  0px !important;
      color: #fff;
      padding: 50px !important;
  }
  .grid-item2 p{
      color: #D5D5D5 !important;
      font-size: 15px !important;
  }
  .grid-item2 h2{
    padding: 5% 0;
  }