.home-content {
  padding: 220px 0 280px;
  width: 80%;
  margin: auto;
  line-height: 40px;
  height: auto;
  .welcome {
    font-size: 39px;
    margin-bottom: 15px;
    color: #fff !important;
  }
  .p_home_content {
    color: #F4C623 !important;
    font-size: 41px;
    font-weight: bold;
  }
}
