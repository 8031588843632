   .scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s, visibility 0.4s;
    cursor: pointer;
    color: #FFB606;
    width: 40px;
    height: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid transparent; 
    font-size: 20px;
  }
  
  .scroll-to-top-button.visible {
    opacity: 1;
    visibility: visible;
  }
  
  .scroll-to-top-button.visible:hover {
    border-color: #FFB606;
  }
  