.OneCategory {
  display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background: rgba(0,0,0,.6);
   
    img {
      width: 27%;       
  }

  div {
    flex: 1; 
    margin-left: 20px; 
}

div h3 {
  color: #fff;
  font-size: 17px;
}
}

.OneCategory:hover{
  background-color: #000;
}

@media (min-width: 600px) and (max-width: 800px) {
  .OneCategory img {
    width: 15%;
  }
}