.category {
  // padding:0 40px;
  // margin-top: 50px;
  // width: 80%;
  margin:-230px auto  50px auto;
  width: 80%;

  h3 {
    margin-bottom: 25px;
    font-size: 25px;
  }
  .flexx {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30%, 1fr)); /* توزيع العناصر بالتساوي مع تحديد الحد الأدنى والأقصى للعرض */
    gap: 20px; /* تباعد بين العناصر */
  }
}
@media (max-width:800px) {
  .flexx {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr)) !important; 
  }
  .category{
    margin: 50px auto;
  }
}
