div#preload {
  margin: auto;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cssload-loader-style-1 {
  margin: auto;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  perspective: 780px;
}

.cssload-loader-style-1 .cssload-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 50%;
  border-bottom: 3px solid #000;
  border-bottom-color: #ffb606;
}

.cssload-loader-style-1 .cssload-one {
  animation: cssload-rotate-one 2.15s linear infinite;
}

.cssload-loader-style-1 .cssload-two {
  animation: cssload-rotate-two 3.15s linear infinite;
}

.cssload-loader-style-1 .cssload-three {
  animation: cssload-rotate-three  5.15s linear infinite;
}

@keyframes cssload-rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes cssload-rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes cssload-rotate-three {
  0% {
    transform: rotateX(45deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(45deg) rotateY(55deg) rotateZ(360deg);
  }
}
