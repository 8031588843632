.notPage {
    text-align: center;
  
    .not {
      width: 18%;
      height: 18%;
      margin: auto;
  
      img {
        width: 100%;
        height: 100%;
        margin-top: 90px;
      }
    }
    h1 {
      color: #FFB606;
      font-weight: bold;
      margin: 2% auto;
    }
    p {
      margin-bottom: 5%;
      font-weight: bold;
      color: #aaa;
    }
  }