.footer {
  display: flex;
  justify-content: center;
  color: #666;
  margin: 60px 0px;
  .container {
    width: 80%;
    }
    hr {
      margin: 1% 0px;
      height: 0;
      border: 1px solid #ebe9e9;
    }

    .bottom {
      text-align: center;
      padding: 2% 0 0;
    }
  }
