.ResultContainer {
    margin: auto;
    width: 80%;
    text-align: center;
  
  h1{
    color: #F9ca24;
    margin-bottom: 2%;
    font-size: 45px;
  }
  p{
  color: #fff;
  font-size: 28px;
  }
  .ResultContent{
    padding: 15% 0 5%;
  }
  }
  
  @media (max-width: 800px) {
    .ResultContainer h1{
      font-size: 30px;
      margin-top: 20%;
    }
    .ResultContainer p{
      font-size: 23px;
    }
  }